import React, { useEffect } from 'react';
import {
  TimeSheetTable,
  useAccessToken,
  useDaysOfWeek,
  WorkItemsDescriptionTable,
} from '@portal/frontend/react';
import { useSearchParams } from 'react-router-dom';

export const DevPortalWrapper = () => {
  const [daysOfWeek, setDaysOfWeek] = useDaysOfWeek();
  const {
    decodedToken: { sub: employeeId },
  } = useAccessToken();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.has('showRecentTasks')) {
      searchParams.set('showRecentTasks', 'true');
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  return (
    <>
      <div className="d-flex justify-content-center">
        <TimeSheetTable
          employeeId={employeeId}
          daysOfWeek={daysOfWeek}
          setDaysOfWeek={setDaysOfWeek}
        />
      </div>

      <div className="mb-5"></div>

      <div className="d-flex justify-content-center">
        <WorkItemsDescriptionTable
          employeeId={employeeId}
          daysOfWeek={daysOfWeek}
        />
      </div>
    </>
  );
};
