import { CellStyle, Row } from '@silevis/reactgrid';
import moment from 'moment';
import { Moment } from 'moment-business-days';
import { DeveloperPortalCellTypes } from '../interfaces/reactgrid.interface';
import { getHeaderCell, getNumberCell } from '../templates/cellGetters';

export const getTotalRows = (
  days: Moment[],
  summedUpHours: number[],
): Row<DeveloperPortalCellTypes>[] => {
  const style: CellStyle = {
    border: {
      left: { color: 'green', style: 'solid', width: '2px' },
      bottom: { color: 'green', style: 'solid', width: '2px' },
      right: { color: 'green', style: 'solid', width: '2px' },
    },
  };
  return [
    {
      rowId: 'total',
      height: 35,
      cells: [
        getHeaderCell('Total', {}, 'top-line-style style-1'),
        getHeaderCell('', {}, 'top-line-style style-1'),
        getHeaderCell('', {}, 'top-line-style style-1'),
        ...days.map((day, idx) => {
          const styles =
            moment(day).format('DD-MM-YYYY') ===
            moment(new Date()).format('DD-MM-YYYY')
              ? style
              : {};
          return getNumberCell(
            summedUpHours[idx],
            styles,
            'top-line-style style-2',
            true,
          );
        }),
        getHeaderCell('', {}, 'top-line-style style-1'),
      ],
    },
  ];
};
