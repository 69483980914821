import { Row, TextCell } from '@silevis/reactgrid';
import moment from 'moment';
import { Moment } from 'moment-business-days';
import {
  getExtendedHeaderCell,
  getHeaderCell,
  getTextCell,
} from '../templates/cellGetters';
import { style2 } from './getDaysOfWeekRows';
import { Project } from '../interfaces/Project.interface';
import {
  DeveloperPortalCellTypes,
  RowCell,
} from '../interfaces/reactgrid.interface';
import { nonEditableStatuses } from '../enums/NonEditableStatus.enum';
import { shouldBeLocked } from './shouldBeLocked';
import { UserRole } from '../enums/UserRole.enum';

export const getWorkItemRows = (
  project: Project,
  days: Moment[],
  employeeLockDate: string,
  employeeRole: UserRole,
): Row<DeveloperPortalCellTypes>[] => {
  const projectRows: Row<DeveloperPortalCellTypes> = {
    rowId: project.id,
    height: 35,
    cells: [
      {
        type: 'nonEditableChevron',
        text: project.name,
        isExpanded: !project.hidden,
        className: 'style-1 text-bold',
        hasChildren: true,
      },
      getTextCell('', {}, 'style-1'),
      getTextCell('', {}, 'style-1'),
      ...days.map((day): TextCell => {
        const styles =
          moment(day).format('DD-MM-YYYY') ===
          moment(new Date()).format('DD-MM-YYYY')
            ? { ...style2 }
            : {};
        return getTextCell('', styles, 'style-1', true);
      }),
      getTextCell('', {}, 'style-1'),
    ],
  };

  const workItemRows: Row<RowCell>[] = project.workItems.map((workItem) => {
    const foundWorkItemRoleHour = workItem.workItemRoleHours?.find(
      (item) => item.role.id === project.collaborators[0].role.id,
    );
    return {
      rowId: workItem.id,
      height: 30,
      cells: [
        getExtendedHeaderCell(
          workItem.name,
          {
            edit: false,
          },
          { paddingLeft: '25px' },
          'style-2',
        ),
        getExtendedHeaderCell(
          workItem.extId,
          {
            edit: false,
          },
          null,
          'style-2',
        ),
        getTextCell('', {}, 'style-2'),
        ...days.map((day): RowCell => {
          const result = workItem.timetableEntries.find((timeTableEntry) =>
            moment(timeTableEntry.date).isSame(day, 'date'),
          );

          if (
            nonEditableStatuses.some((status) => status === workItem.status)
          ) {
            const styles =
              moment(day).format('DD-MM-YYYY') ===
              moment(new Date()).format('DD-MM-YYYY')
                ? { ...style2, background: '#dedede' }
                : { background: '#dedede' };
            return getHeaderCell(
              `${result?.hours || ''}`,
              styles,
              'justify-content-end',
            );
          }

          const isTimeLoggingLocked = shouldBeLocked(
            moment(employeeLockDate, 'DD-MM-YYYY').toDate(),
            moment(day).toDate(),
            employeeRole,
          );

          return {
            nonEditable: isTimeLoggingLocked,
            className: isTimeLoggingLocked ? 'non-editable-cell' : '',
            type: 'number',
            value: result?.hours || NaN,
            style:
              moment(day).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
                ? style2
                : {},
            hideZero: true,
            collaboratorId: project.collaborators[0].id,
            workItemRoleHoursId: foundWorkItemRoleHour?.id,
          };
        }),
        getTextCell('', null, 'style-2'),
      ],
    };
  });

  return project.hidden ? [projectRows] : [projectRows, ...workItemRows];
};
